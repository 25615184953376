@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  background-color: black;
  overflow-x: "hidden";
}

body {
  height: 100%;
  min-height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;

  font-family: 'SkyerRegular', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  min-height: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.planetdiv {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  overflow: hidden;
}

.starmap {
  top: 0;
  background-color: black;
  z-index: -4;
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden !important;
  /* position: absolute; */
}

.starmapfloating {
  top: 0;
  background-color: transparent;
  z-index: -4;
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden !important;
  /* position: absolute; */
}

.dialog-enter {
  opacity: 0.01;
  transform: scale(1.1);
}

.dialog-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}

.dialog-exit {
  opacity: 1;
  transform: scale(1);
}

.dialog-exit-active {
  opacity: 0.01;
  transform: scale(1.1);
  transition: all 300ms;
}

.gradientText {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.satellites-scrollbar::-webkit-scrollbar {
  width: 5px;
	background-color: #F5F5F5;
}

.satellites-scrollbar::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #1b1f27;
}

.filter-ul {
  position: absolute;
}
.nav-button-toggler {
  outline: none !important;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 45px;
  height: 45px;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.top-view {
  z-index: 1;
}

.rotate {
  animation: rotation 20s infinite linear;
}

.web3modal-modal-lightbox {
  background-color: transparent !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.social-icons {
  filter: invert(1);
}

